import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'configuracoes',
    loadChildren: () => import('./pages/configuracoes/configuracoes.module').then( m => m.ConfiguracoesPageModule)
  },
  {
    path: 'gerenciamento-de-usuario',
    loadChildren: () => import('./pages/gerenciamento-de-usuario/gerenciamento-de-usuario.module').then( m => m.GerenciamentoDeUsuarioPageModule)
  },
  {
    path: 'consumo-de-produtos',
    loadChildren: () => import('./pages/consumo-de-produtos/consumo-de-produtos.module').then( m => m.ConsumoDeProdutosPageModule)
  },
  {
    path: 'monitoramento',
    loadChildren: () => import('./pages/monitoramento/monitoramento.module').then( m => m.MonitoramentoPageModule)
  },
  {
    path: 'bd-monitoramento',
    loadChildren: () => import('./pages/bd-monitoramento/bd-monitoramento.module').then( m => m.BdMonitoramentoPageModule)
  },
  {
    path: 'detalhes-monitoramento',
    loadChildren: () => import('./pages/detalhes-monitoramento/detalhes-monitoramento.module').then( m => m.DetalhesMonitoramentoPageModule)
  },
  {
    path: 'detalhes-monitoramento/:id/:cliente/:atividade/:estado/:data_do_desvio/:mes/:responsavel/:numero_do_mes/:floculacao/:acao_floculacao/:decantacao/:acao_decantacao/:flotacao/:acao_flotacao/:dosadoras/:acao_dosadoras/:ph_agua_bruta/:ph_agua_tratada/:filtracao/:acao_filtracao/:retro_lavagem_filtros/:acao_retro_lavagem_filtros/:descarga_de_fundo/:acao_descarga_de_fundo/:nivel_produtos/:acao_nivel_produtos/:observacoes/:data',
    loadChildren: () => import('./pages/detalhes-monitoramento/detalhes-monitoramento.module').then( m => m.DetalhesMonitoramentoPageModule)
  },
  {
    path: 'pedido-de-compras',
    loadChildren: () => import('./pages/pedido-de-compras/pedido-de-compras.module').then( m => m.PedidoDeComprasPageModule)
  },
  {
    path: 'bd-compras',
    loadChildren: () => import('./pages/bd-compras/bd-compras.module').then( m => m.BdComprasPageModule)
  },
  {
    path: 'home-financeiro',
    loadChildren: () => import('./pages/home-financeiro/home-financeiro.module').then( m => m.HomeFinanceiroPageModule)
  },
  {
    path: 'recebimento',
    loadChildren: () => import('./pages/recebimento/recebimento.module').then( m => m.RecebimentoPageModule)
  },
  {
    path: 'home-recebimento',
    loadChildren: () => import('./pages/home-recebimento/home-recebimento.module').then( m => m.HomeRecebimentoPageModule)
  },
  {
    path: 'bd-recebimento',
    loadChildren: () => import('./pages/bd-recebimento/bd-recebimento.module').then( m => m.BdRecebimentoPageModule)
  },
  {
    path: 'compras-finalizadas',
    loadChildren: () => import('./pages/compras-finalizadas/compras-finalizadas.module').then( m => m.ComprasFinalizadasPageModule)
  },
  {
    path: 'controle-de-estoque',
    loadChildren: () => import('./pages/controle-de-estoque/controle-de-estoque.module').then( m => m.ControleDeEstoquePageModule)
  },
  {
    path: 'bd-estoque',
    loadChildren: () => import('./pages/bd-estoque/bd-estoque.module').then( m => m.BdEstoquePageModule)
  },
  {
    path: 'entrada',
    loadChildren: () => import('./pages/entrada/entrada.module').then( m => m.EntradaPageModule)
  },
  {
    path: 'requisicao',
    loadChildren: () => import('./pages/requisicao/requisicao.module').then( m => m.RequisicaoPageModule)
  },
  {
    path: 'home-requisicoes',
    loadChildren: () => import('./pages/home-requisicoes/home-requisicoes.module').then( m => m.HomeRequisicoesPageModule)
  },
  {
    path: 'requisicoes-aguardando',
    loadChildren: () => import('./pages/requisicoes-aguardando/requisicoes-aguardando.module').then( m => m.RequisicoesAguardandoPageModule)
  },
  {
    path: 'inventario',
    loadChildren: () => import('./pages/inventario/inventario.module').then( m => m.InventarioPageModule)
  },
  {
    path: 'historico-inventario',
    loadChildren: () => import('./pages/historico-inventario/historico-inventario.module').then( m => m.HistoricoInventarioPageModule)
  },
  {
    path: 'historico-requisicao',
    loadChildren: () => import('./pages/historico-requisicao/historico-requisicao.module').then( m => m.HistoricoRequisicaoPageModule)
  },
  {
    path: 'retorno-de-materiais',
    loadChildren: () => import('./pages/retorno-de-materiais/retorno-de-materiais.module').then( m => m.RetornoDeMateriaisPageModule)
  },
  {
    path: 'historico-entrada',
    loadChildren: () => import('./pages/historico-entrada/historico-entrada.module').then( m => m.HistoricoEntradaPageModule)
  },
  {
    path: 'cad-fornecedor',
    loadChildren: () => import('./pages/cad-fornecedor/cad-fornecedor.module').then( m => m.CadFornecedorPageModule)
  },
  {
    path: 'home-cadastros',
    loadChildren: () => import('./pages/home-cadastros/home-cadastros.module').then( m => m.HomeCadastrosPageModule)
  },
  {
    path: 'pedido-de-compras-tela2',
    loadChildren: () => import('./pages/pedido-de-compras-tela2/pedido-de-compras-tela2.module').then( m => m.PedidoDeComprasTela2PageModule)
  },
  {
    path: 'saida-projetos',
    loadChildren: () => import('./pages/saida-projetos/saida-projetos.module').then( m => m.SaidaProjetosPageModule)
  },
  {
    path: 'home-relatorios',
    loadChildren: () => import('./pages/home-relatorios/home-relatorios.module').then( m => m.HomeRelatoriosPageModule)
  },
  {
    path: 'indicadores-servico',
    loadChildren: () => import('./pages/indicadores-servico/indicadores-servico.module').then( m => m.IndicadoresServicoPageModule)
  },
  {
    path: 'cad-cliente',
    loadChildren: () => import('./pages/cad-cliente/cad-cliente.module').then( m => m.CadClientePageModule)
  },
  {
    path: 'home-projetos',
    loadChildren: () => import('./pages/home-projetos/home-projetos.module').then( m => m.HomeProjetosPageModule)
  },
  {
    path: 'plano-de-priorizacao',
    loadChildren: () => import('./pages/plano-de-priorizacao/plano-de-priorizacao.module').then( m => m.PlanoDePriorizacaoPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
